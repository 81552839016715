@import '../../../styles/customMediaQueries.css';

.root {
  position: relative;
  display: inline-block;
}

.fieldGroup {
  margin: 0;
  padding: 0;
  border: none;
}

.fieldGroupPlain {
  margin: 0;
  padding: 0;
  border: none;
}

.list {
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}

.item {
  padding: 2px 0;
  width: calc(100% / 2);
  /* Fix broken multi-column layout in Chrome */
  page-break-inside: avoid;

  @media (--viewportMedium) {
    padding: 4px 0;
  }
  & > span {
    & > label {
      & > span {
        font-size: 16px;
        font-weight: normal;
        color: var(--colorGrey500);
      }
    }
  }
  &.typeCheckbox {
    position: relative;
    padding: 0;
    &:first-child {
      & > span {
        & label {
          border-top-left-radius: var(--borderRadiusMedium);
          border-bottom-left-radius: var(--borderRadiusMedium);
        }
      }
    }
    &:last-child {
      & > span {
        & label {
          border-top-right-radius: var(--borderRadiusMedium);
          border-bottom-right-radius: var(--borderRadiusMedium);
        }
      }
    }

    & > span {
      & > input:checked + label {
        background-color: var(--colorBlack);
        border: solid 1px var(--colorBlack);
        color: var(--colorWhite);
        & > span {
          color: var(--colorWhite);
        }
      }
      & label {
        text-align: center;
        margin: 0;
        border: solid 1px var(--colorGrey100);
        transition: var(--transitionStyle);
        padding: 10px 20px;
        &:hover {
          transition: var(--transitionStyle);
          border: solid 1px var(--colorBlack);
        }
        &::before {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          content: '';
          z-index: 1;
          cursor: pointer;
          background-image: linear-gradient(to bottom, rgba(255 255 255/0.16), rgba(255 255 255/0));
        }
        & > span {
          padding: 0;
          margin: 0;
          width: 100%;
          text-align: center;
          font-size: 18px;
          line-height: 130%;
          font-weight: var(--fontWeightBold);
          min-height: 40px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          position: relative;
          &:first-child {
            display: none;
          }
        }
      }
    }
  }
}
