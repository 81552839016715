@import '../../../styles/customMediaQueries.css';

.root {
  display: none;

  /* Borders */
  outline: none;
}

.popup {
  /* By default hide the content */
  display: block;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;

  /* Position */
  position: absolute;
  z-index: var(--zIndexPopup);

  /* Layout */
  padding: 15px 30px 20px 30px;
  margin-top: 7px;
  background-color: var(--colorWhite);

  /* Borders */
  border-top: 1px solid var(--colorGrey100);
  box-shadow: var(--boxShadowPopup);
  border-radius: var(--borderRadiusMedium);
  transition: var(--transitionStyleButton);
}

.isOpenAsPopup {
  display: block;
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}

.plain {
  width: 100%;

  @media (--viewportMedium) {
    padding-top: 8px;
    padding-bottom: 16px;
  }
}

.isOpen {
  display: block;
}

.contentWrapper {
  display: flex;
  width: 100%;
  margin-top: 60px;
}

.label {
  composes: h5 from global;

  font-weight: var(--fontWeightSemiBold);
  padding: 11px 0 13px 0;
  margin: 0 18px 0 0;
}
@media (--viewportMedium) {
  .label {
    padding: 8px 0 16px 0;
  }
}

.inputsWrapper {
  display: flex;
  width: 100%;
}

.minPrice,
.maxPrice {
  width: calc(100% / 2);
  padding: 0 15px;
  -moz-appearance: textfield;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.priceInSidebar {
}

.priceSeparator {
  margin: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sliderWrapper {
  display: flex;
  margin-top: 12px;
}

.buttonsWrapper {
  display: flex;
}

.clearButton,
.cancelButton,
.submitButton {
  composes: textSmall from global;
  font-weight: var(--fontWeightMedium);

  /* Layout */
  padding: 0;

  /* Override button styles */
  outline: none;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    transition: width var(--transitionStyleButton);
  }
}

.clearButton {
  color: var(--colorGrey300);

  /* Layout */
  margin: 0 auto 0 0;

  &:focus,
  &:hover {
    color: var(--colorGrey700);
  }
}

.cancelButton {
  color: var(--colorGrey300);

  /* Layout */
  margin: 0;

  /* clearButton will add all available space between cancelButton,
   * but some hard coded margin is still needed
   */
  margin-left: 48px;

  &:focus,
  &:hover {
    color: var(--colorGrey700);
  }
}

.submitButton {
  color: var(--marketplaceColor);

  /* Layout */
  margin: 0 0 0 19px;

  &:focus,
  &:hover {
    color: var(--marketplaceColorDark);
  }
}
